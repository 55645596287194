import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, Popconfirm, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useTableParams } from 'hooks/useTableParams';
import useLoading from 'hooks/useLoading';
import { useToggle } from 'hooks/useToggle';
import { getRegions, getTransferConfig, removeRegion } from 'store/transfer-config';
import CreateRegionModal from './CreateRegionModal';

const RegionsModal = () => {
  const dispatch = useDispatch();
  const { active, deactivate, activate } = useToggle();
  const { handleTableChange, params } = useTableParams();
  const { data, loading, execute } = useLoading({ docs: [], totalDocs: 0 });
  const { loading: removeLoading, execute: executeRemove } = useLoading();

  const fetchRegions = useCallback(
    (queryParams) => {
      execute(() => dispatch(getRegions(queryParams ?? params)));
    },
    [execute, dispatch, params],
  );

  useEffect(() => {
    if (active) {
      fetchRegions({});
    }
  }, [active, fetchRegions]);

  const deleteRegion = useCallback(
    async (id) => {
      await executeRemove(() => dispatch(removeRegion(id)));
      dispatch(getTransferConfig());
      fetchRegions({});
    },
    [executeRemove, dispatch, fetchRegions],
  );

  const columns = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '',
        dataIndex: '_id',
        key: 'actions',
        align: 'center',
        render: (id) => {
          return (
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => {
                deleteRegion(id);
              }}
            >
              <Button loading={removeLoading} danger icon={<DeleteOutlined />} />
            </Popconfirm>
          );
        },
      },
    ],
    [deleteRegion, removeLoading],
  );

  return (
    <>
      <Modal
        title="Regions"
        open={active}
        onCancel={deactivate}
        footer={[
          <Button key="cancel" onClick={deactivate}>
            Cancel
          </Button>,
          <CreateRegionModal onCreate={fetchRegions} key="create" />,
        ]}
      >
        <Table
          loading={loading}
          dataSource={data.docs}
          columns={columns}
          onChange={handleTableChange}
          pagination={{
            total: data.totalDocs,
            current: params.page,
            pageSize: params.limit,
            style: { marginBottom: 0 },
          }}
        />
      </Modal>
      <Button onClick={activate} type="primary">
        Regions
      </Button>
    </>
  );
};

export default RegionsModal;
