import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Form, Input, Row, Select } from 'antd';
import { natural } from 'utils/validators';
import { getFilteredTournaments } from 'store/serial-tournaments';
import { ImageUpload } from 'components/Form/ImageUpload';
import InputNumber from 'components/Form/InputNumber';

const rules = {
  required: [{ required: true }],
  natural: [{ required: true }, natural],
};

const TournamentInfo = ({ form, filter }) => {
  const dispatch = useDispatch();
  const [tournaments, setTournaments] = useState([]);
  const [enterManually, setEnterManually] = useState(false);
  const { t } = useTranslation();

  const defaultTournament = { value: 0, label: t('playingmyself.create.selector.other') };

  const entries = [
    { value: 'first', label: t('playingmyself.create.selector.first-entry') },
    { value: 'reentry', label: t('playingmyself.create.selector.reentry') },
  ];

  useEffect(() => {
    // noinspection JSUnresolvedReference
    dispatch(getFilteredTournaments(filter)).then((data) =>
      setTournaments([
        ...data.map((t) => ({
          value: t._id,
          label: t.name,
          isSerial: t.isSerial,
        })),
        defaultTournament,
      ]),
    );
  }, [dispatch, filter]);

  const handleTournamentSelect = useCallback(
    (tournamentId) => {
      const values = { tournament: '', serialTournamentId: '', tournamentId: '' };
      if (tournamentId === 0) {
        setEnterManually(true);
      } else if (tournamentId) {
        setEnterManually(false);
        const { value: id, label: name, isSerial } = tournaments.find((t) => t.value === tournamentId);
        values.tournament = name;
        if (isSerial) {
          values.serialTournamentId = id;
        } else {
          values.tournamentId = id;
        }
      }
      form.setFieldsValue(values);
    },
    [tournaments, form],
  );

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        label={t('playingmyself.create.label.tournament')}
        name="tournamentIdForm"
        rules={[{ required: true, message: 'Required' }]}
      >
        <Select
          virtual={false}
          showSearch
          placeholder={t('playingmyself.create.label.tournament.placeholder')}
          onChange={handleTournamentSelect}
          options={tournaments}
        />
      </Form.Item>
      <Form.Item name="serialTournamentId" hidden>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name="tournamentId" hidden>
        <Input type="hidden" />
      </Form.Item>
      {enterManually && (
        <>
          <Form.Item label={t('playingmyself.create.label.tournament_title')} name="tournament" rules={rules.required}>
            <Input placeholder={t('playingmyself.create.label.tournament_title.placeholder')} />
          </Form.Item>
          <Form.Item label={t('playingmyself.create.label.tournament_id')} name="tournamentExternalId">
            <Input placeholder={t('playingmyself.create.label.tournament_id.placeholder')} />
          </Form.Item>
        </>
      )}
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item label={t('playingmyself.create.label.bbcount')} name="bbCount" rules={rules.natural}>
            <InputNumber
              placeholder={t('playingmyself.create.label.bbcount.placeholder')}
              precision={0}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t('playingmyself.create.label.entry')} name="entry" rules={rules.required}>
            <Select
              virtual={false}
              placeholder={t('playingmyself.create.label.entry.placeholder')}
              style={{ width: '100%' }}
              options={entries}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label={t('playingmyself.create.label.screenshot')} name="screenshot">
        <ImageUpload />
      </Form.Item>
      <Form.Item label={t('playingmyself.create.label.comment')} name="comment" style={{ marginBottom: 0 }}>
        <Input.TextArea placeholder={t('playingmyself.create.label.comment.placeholder')} />
      </Form.Item>
    </Form>
  );
};

export default TournamentInfo;
