import { Table } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { getMyPaidPackages, selectPaidPackages } from "store/packages";
import { useCallback, useEffect, useState } from "react";
import { VerifiedSplitModal } from "./VerifiedSplitModal";
import { useTranslation } from 'react-i18next';

export const ClosedTable = () => {
  const { paidPackages, loading } = useSelector(selectPaidPackages);
  const dispatch = useDispatch();
  const [activePackage, setActivePackage] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getMyPaidPackages());
  }, [dispatch]);

  const onRowClick = useCallback((record) => {
    setActivePackage(record);
  }, []);

  return (
    <>
      <Table
        loading={loading}
        onRow={(record) => ({ onClick: () => onRowClick(record) })}
        dataSource={paidPackages.docs}
        pagination={{
          total: paidPackages.totalDocs,
          showSizeChanger: true
        }}
      >
        <Table.Column
          title={t('split.closed_table.buy_in_percentage')}
          dataIndex="buyInPercent"
          key="buyInPercent"
          align="center"
          render={(buyInPercent) => `${buyInPercent} %`}
        />
        <Table.Column
          title={t('split.closed_table.profit_percentage')}
          dataIndex="profitPercent"
          key="profitPercent"
          align="center"
          render={(profitPercent) => `${profitPercent} %`}
        />
        <Table.Column
          title={t('split.closed_table.open_date')}
          dataIndex="createdAt"
          key="createdAt"
          align="center"
          render={(createdAt) => (createdAt ? new Date(createdAt).toLocaleString() : '-')}
        />
        <Table.Column
          title={t('split.closed_table.closed_date')}
          dataIndex="closedAt"
          key="closedAt"
          align="center"
          render={(closedAt) => (closedAt ? new Date(closedAt).toLocaleString() : '-')}
        />
        <Table.Column
          title={t('split.closed_table.approve_date')}
          dataIndex="paidAt"
          key="paidAt"
          align="center"
          render={(paidAt) => (paidAt ? new Date(paidAt).toLocaleString() : '-')}
        />
      </Table>
      <VerifiedSplitModal activePackage={activePackage} onClose={() => setActivePackage(null)} />
    </>
  );
};
