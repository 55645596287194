import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Descriptions, InputNumber, notification, Space, Tag } from 'antd';
import { getHold, updateHold } from 'store/holds';
import { getUserTotals } from 'store/statistics';
import { getLatest, selectRates } from 'store/rates';

export const HoldsWidget = ({ userId }) => {
  const dispatch = useDispatch();
  const [holdAmount, setHoldAmount] = useState();
  const [holdRate, setHoldRate] = useState();
  const [amount, setAmount] = useState(0);
  const [totals, setTotals] = useState({});
  const { latest } = useSelector(selectRates);
  const [difference, setDifference] = useState(0);

  useEffect(() => {
    dispatch(getLatest());
    dispatch(getHold(userId)).then((response) => {
      setHoldAmount(response?.amount ? response.amount : 0);
      setHoldRate(response?.rate ? response.rate : 0);
    });
    dispatch(getUserTotals(userId)).then((response) => {
      setTotals(response);
    });
  }, []);

  useEffect(() => {
    if (latest?.ratio && holdRate && holdAmount) {
      setDifference(holdAmount * latest?.ratio - holdAmount * holdRate);
    } else {
      setDifference(0);
    }
  }, [latest, holdRate, holdAmount]);

  const holdSubmit = useCallback(() => hold(amount), [amount]);

  const unholdSubmit = useCallback(() => hold(-amount), [amount]);

  const hold = useCallback(
    async (amount) => {
      if (amount !== 0) {
        try {
          dispatch(updateHold(userId, { amount }))
            .then((response) => {
              setHoldAmount(response.amount);
              setHoldRate(response.rate);
              setAmount(0);
            })
            .then(() => {
              notification.success({
                message: 'The hold has been updated',
              });
            });
        } catch (e) {
          console.error(e);
        }
      } else {
        notification.warn({
          message: 'Hold amount cannot be 0',
        });
      }
    },
    [userId],
  );

  return (
    <>
      <Descriptions column={1} bordered style={{ marginBottom: 24 }}>
        <Descriptions.Item label="Personal money">
          {parseFloat(totals?.personalMoney).toFixed(2)} USD | ({difference.toFixed(2)} USD)
        </Descriptions.Item>
        <Descriptions.Item label="Euro total">{parseFloat(totals?.accountsEuroTotal).toFixed(2)} EUR</Descriptions.Item>
        <Descriptions.Item label="Current hold">
          {parseFloat(holdAmount).toFixed(2)}{' '}
          <Space>
            EUR <Tag color="blue">{holdRate ? parseFloat(holdRate).toFixed(6) : 'No rate'} Av.R</Tag>
          </Space>
        </Descriptions.Item>
      </Descriptions>
      <Space>
        <InputNumber
          min={0}
          precision={2}
          style={{ minWidth: 200 }}
          onChange={(value) => setAmount(value)}
          placeholder="Hold amount"
          value={amount}
        />
        <Button type="primary" onClick={holdSubmit}>
          Hold
        </Button>
        <Button type="danger" onClick={unholdSubmit}>
          Unhold
        </Button>
      </Space>
    </>
  );
};
