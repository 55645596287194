import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Form, Input, InputNumber, Modal, Row, Select, Tabs } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import debounce from 'lodash.debounce';
import { CountryRegionData } from 'react-country-region-selector';
import { useToggle } from 'hooks/useToggle';
import { mapFormErrors, toUpperCase } from 'utils/format';
import { getUserById, selectUser, updateUser, validateUnique } from 'store/users';
import { useAccounts, useDegree } from '../../hooks';
import { mapInitialValues } from '../../utils';
import { List as AccountsList } from '../Accounts/List';
import { HoldsWidget } from './HoldsWidget';
import { ResetPasswordModal } from './ResetPasswordModal';

const UpdateModal = ({ id, onOk }) => {
  const dispatch = useDispatch();
  const { active, activate, deactivate } = useToggle();
  const { user, loading } = useSelector(selectUser);
  const [form] = Form.useForm();

  const { createdAccounts, setCreatedAccounts, onCreateAccount, onUpdateAccount, onDeleteAccount } = useAccounts();
  const onDegreeChange = useCallback(
    (valid) => {
      if (!valid) {
        form.resetFields(['course']);
      }
    },
    [form],
  );
  const { degree, setDegree, onCourseChange } = useDegree(onDegreeChange);

  useEffect(() => {
    if (active) {
      dispatch(getUserById(id));
    }
  }, [dispatch, id, active]);

  useEffect(() => {
    if (user) {
      form.setFieldsValue(mapInitialValues(user));
      setCreatedAccounts(user.accounts);
      setDegree(user.degree);
    }
  }, [user, form]);

  const validateEmail = useCallback(
    async (e) => {
      try {
        await dispatch(validateUnique({ email: e.target.value, userId: id }));
      } catch (e) {
        const code = e.response?.status;
        if (code === 409) {
          form.setFields([
            {
              name: 'email',
              errors: ['Email is already taken'],
            },
          ]);
        }
      }
    },
    [id, dispatch, form],
  );

  const update = useCallback(
    async (data) => {
      try {
        await dispatch(
          updateUser(id, { ...data, accounts: createdAccounts.map((acc) => acc._id), username: data.username.trim() }),
        );
        deactivate();
        form.resetFields();
        onOk();
      } catch (e) {
        form.setFields(mapFormErrors(e));
      }
    },
    [form, createdAccounts, deactivate, dispatch, onOk],
  );

  const UserInfo = (
    <Row gutter={10}>
      <Col span={12}>
        <Card title="Personal info">
          <Form.Item label="Login" name="username" rules={[{ required: true }]}>
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="E-mail"
            name="email"
            rules={[{ required: true, type: 'email', message: 'Please, use the valid email' }]}
          >
            <Input onChange={debounce(validateEmail, 500)} />
          </Form.Item>
          <Form.Item
            label="Full Name (Latin)"
            name="name"
            rules={[
              { required: true, message: 'Enter the full name' },
              { pattern: new RegExp('^[a-zA-Z\\s-]*$'), message: 'Use latin letters only' },
            ]}
            normalize={toUpperCase}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Discord ID" name="discordId">
            <Input />
          </Form.Item>
          <Row gutter={10} style={{ marginBottom: -24 }}>
            <Col span={14}>
              <Form.Item label="Country" name="country" rules={[{ required: true }]}>
                <Select name="country" showSearch>
                  {CountryRegionData.map((country) => (
                    <Select.Option key={country[1]} value={country[0]}>
                      {country[0]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="Course" name="scheduleCourse" rules={[{ required: true }]} className="no-margin">
                <InputNumber precision={2} onChange={onCourseChange} addonAfter={degree} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={12}>
        <Card title="Hold">
          <HoldsWidget userId={id} />
        </Card>
      </Col>
    </Row>
  );

  const AccountsInfo = (
    <>
      <Form.Item hidden name="accounts" noStyle />
      <AccountsList
        list={createdAccounts}
        createdAccounts={createdAccounts}
        onCreateAccount={onCreateAccount}
        onUpdateAccount={onUpdateAccount}
        onDeleteAccount={onDeleteAccount}
        allowBalanceUpdate={false}
      />
    </>
  );

  const tabs = [
    { label: 'User', key: 'user', children: UserInfo },
    { label: 'Accounts', key: 'accounts', children: AccountsInfo },
  ];

  const footer = useMemo(() => {
    const buttons = [
      <Button key="cancel" onClick={deactivate}>
        Cancel
      </Button>,
    ];

    if (user) {
      buttons.push(<ResetPasswordModal key="reset-password" user={user} />);
    }

    buttons.push(
      <Button type="primary" key="update" onClick={form.submit} loading={loading}>
        Update
      </Button>,
    );

    return buttons;
  }, [user, form.submit, loading, deactivate]);

  return (
    <>
      <Button type="primary" icon={<EditOutlined />} onClick={activate} />
      <Modal
        open={active}
        title={`Update ${user?.username}`}
        width={1000}
        style={{ top: 20 }}
        onCancel={deactivate}
        footer={footer}
      >
        <Form form={form} layout="vertical" onFinish={update}>
          <Tabs items={tabs} />
        </Form>
      </Modal>
    </>
  );
};

export default UpdateModal;
