import { useCallback, useState } from 'react';

const defaultOptions = { throwError: false };

export default (initialData = null, initialLoading = false) => {
  const [state, setState] = useState({
    loading: initialLoading,
    data: initialData,
    error: null,
  });

  const execute = useCallback(async (action, { throwError } = defaultOptions) => {
    setState((prevState) => ({ ...prevState, loading: true }));

    try {
      const result = await action();

      setState({
        loading: false,
        data: result,
        error: null,
      });

      return result;
    } catch (e) {
      if (throwError) {
        setState((prevState) => ({
          ...prevState,
          loading: false,
        }));

        throw e;
      }

      setState((prevState) => ({
        ...prevState,
        loading: false,
        error: e,
      }));

      return null;
    }
  }, []);

  return { execute, ...state };
};
