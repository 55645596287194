import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Popconfirm, Table } from 'antd';
import { toAmount } from 'utils/format';
import { useTableParams } from 'hooks/useTableParams';
import { close } from 'store/transfers';
import { selectUser } from 'store/auth';
import { DonateModal } from './DonateModal';

const getHoursBetweenDates = (date1, date2) => {
  const diff = Math.abs(date1 - date2);
  return Math.ceil(diff / (1000 * 60 * 60));
};

export const TransfersTable = ({ list, loading, onChange }) => {
  const dispatch = useDispatch();
  const { params, handleTableChange } = useTableParams();
  const user = useSelector(selectUser);
  const { t } = useTranslation();

  useEffect(() => {
    onChange(params);
  }, [onChange, params]);

  const columns = useMemo(
    () => [
      {
        title: t('account'),
        dataIndex: 'account',
        key: 'account',
        render: (account) => `${account.type} - ${account.currency}`,
      },
      {
        title: t('amount'),
        dataIndex: 'currentAmount',
        key: 'currentAmount',
        render: (currentAmount, transfer) =>
          toAmount(transfer.requestedAmount - currentAmount, transfer.account.currency),
        sorter: true,
      },
      {
        title: t('transfers.created_at'),
        dataIndex: 'createdAt',
        key: 'date',
        defaultSortOrder: 'ascend',
        sorter: true,
        render: (createdAt) => new Date(createdAt).toLocaleString(),
      },
      {
        dataIndex: '_id',
        key: 'actions',
        render: (id, request) => {
          if (user._id === request.user._id) {
            return (
              <Popconfirm title={t('areYouSure')} onConfirm={() => dispatch(close(id))}>
                <Button type="primary" danger>
                  {t('close')}
                </Button>
              </Popconfirm>
            );
          }

          return (
            <DonateModal request={request} disabled={request?.reserve?.userId && request.reserve.userId !== user._id} />
          );
        },
      },
    ],
    [user, t],
  );

  return (
    <Table
      rowClassName={(record) =>
        getHoursBetweenDates(new Date(), new Date(record.createdAt)) > 24 ? 'transfer-expired' : ''
      }
      loading={loading}
      rowKey="_id"
      pagination={{ total: list.totalDocs, pageSize: list.limit, current: list.page, style: { marginBottom: 0 } }}
      dataSource={list.docs}
      columns={columns}
      onChange={handleTableChange}
    />
  );
};
