import { useMemo } from 'react';
import { Select } from 'antd';
import { ACCOUNT_REGIONS } from 'config/accounts';

const AccountStatusSelect = ({ type, ...props }) => {
  const statusOptions = useMemo(
    () => ACCOUNT_REGIONS[type].statuses.map((status) => ({ value: status, label: status })),
    [type],
  );

  return <Select options={statusOptions} style={{ width: '100%' }} {...props} />;
};

export default AccountStatusSelect;
