import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal, notification, Popconfirm, Select } from 'antd';
import { SplitCellsOutlined } from '@ant-design/icons';
import {
  createPackage,
  finishPackage,
  getMyActivePackage,
  getMyUnapprovedPackage,
  selectMyActivePackage,
} from 'store/packages';
import { selectUser } from 'store/auth';
import { getMyWaitingForReportRequests } from 'store/playing-myself';
import { getTotals, selectStatistics } from 'store/statistics';
import { rangeWithStep } from 'utils/generators';
import { toBuyInPercent } from 'utils/format';
import { getMaxPercent } from 'utils/users';
import { useToggle } from 'hooks/useToggle';
import AccountListScreenshot from './AccountListScreenshot';

const FormItem = Form.Item;

export const SplitModal = () => {
  const dispatch = useDispatch();

  const [myUnapproved, setMyUnapproved] = useState(null);
  const [buyInPercent, setBuyInPercent] = useState(undefined);
  const [myWaitingForReportPMRequests, setMyWaitingForReportPMRequests] = useState(null);
  const { activate, deactivate, active } = useToggle();
  const { myActive, loading } = useSelector(selectMyActivePackage);
  const { packageProfit } = useSelector(selectStatistics);

  const user = useSelector(selectUser);
  const { t } = useTranslation();

  useEffect(() => {
    // noinspection JSUnresolvedReference
    dispatch(getMyUnapprovedPackage()).then((res) => {
      if (res?.data) {
        setMyUnapproved(res.data);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (myActive) {
      // noinspection JSUnresolvedReference
      dispatch(getMyWaitingForReportRequests({ closedAt: new Date(myActive.createdAt).getTime(), count: true })).then(
        (data) => {
          setMyWaitingForReportPMRequests(data);
        },
      );
    }
  }, [dispatch, myActive]);

  useEffect(() => {
    setBuyInPercent(myActive?.buyInPercent);
  }, [myActive]);

  const percentValues = useMemo(
    () =>
      user ? rangeWithStep(0, getMaxPercent(user.course), 5).map((p) => ({ value: p, label: toBuyInPercent(p) })) : [],
    [user],
  );

  const initiateSplit = useCallback(async () => {
    const data = await dispatch(finishPackage(myActive._id));
    await dispatch(
      createPackage(user._id, {
        buyInPercent,
        excessiveProfit: data.excessiveProfit,
      }),
    );
    await dispatch(getMyActivePackage());
    await dispatch(getTotals());
    deactivate();
    notification.success({
      message: t('splitSuccessful'),
      duration: 0,
    });
  }, [myActive, deactivate, buyInPercent, t]);

  const isActive = useMemo(
    () => !!myActive && packageProfit > 0 && !myUnapproved && myWaitingForReportPMRequests === 0,
    [myActive, packageProfit, myUnapproved, myWaitingForReportPMRequests],
  );

  return (
    <>
      <Button type="primary" icon={<SplitCellsOutlined />} onClick={activate} disabled={!isActive}>
        {t('split')}
      </Button>
      <Modal
        closable={false}
        footer={[
          <Button key="cancel" onClick={deactivate}>
            {t('cancel')}
          </Button>,
          <Popconfirm
            key="submit"
            title={t('confirmScreenshotUpload')}
            onConfirm={initiateSplit}
            okText={t('confirm')}
            cancelText={t('cancel')}
          >
            <Button type="primary" loading={loading}>
              {t('split')}
            </Button>
          </Popconfirm>,
        ]}
        open={active}
        destroyOnClose
      >
        <FormItem label={t('newPackage')} name="buyInPercent" rules={[{ required: true }]} style={{ marginBottom: 0 }}>
          <Select
            loading={loading}
            name="buyInPercent"
            disabled={myActive?.onFreeze}
            value={buyInPercent}
            defaultValue={myActive?.buyInPercent}
            onChange={setBuyInPercent}
            required
            options={percentValues}
            virtual={false}
          />
        </FormItem>
        <AccountListScreenshot />
      </Modal>
    </>
  );
};
