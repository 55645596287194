import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Col, Form, Input, Modal, Row, Select } from 'antd';
import { ACCOUNT_REGIONS } from 'config/accounts';
import { withFormErrors } from 'utils/common';
import { toCurrency } from 'utils/format';
import { getName, sort } from 'utils/account';
import { natural } from 'utils/validators';
import { useToggle } from 'hooks/useToggle';
import { getInner } from 'store/users';
import { create, getAll, selectLoading } from 'store/transfers';
import CountrySelect from 'components/CountrySelect';
import InputNumber from 'components/Form/InputNumber';

const CreateTransferRequestModal = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const { active, activate, deactivate } = useToggle();
  const [adminUser, setAdminUser] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    // noinspection JSUnresolvedReference
    dispatch(getInner()).then(setAdminUser);
  }, [dispatch]);

  const onFinish = useCallback(
    (values) =>
      withFormErrors(form, async () => {
        await dispatch(create({ ...values, user: adminUser._id }));
        dispatch(getAll());
        deactivate();
        form.resetFields();
      }),
    [form, adminUser, dispatch, deactivate],
  );

  const onAccountChange = useCallback(
    (id) => {
      const account = adminUser?.accounts.find((acc) => acc._id === id);
      setSelectedAccount(account);
    },
    [adminUser],
  );

  return (
    <>
      <Button type="primary" onClick={activate}>
        Create request
      </Button>
      <Modal title="Create request" open={active} confirmLoading={loading} onOk={form.submit} onCancel={deactivate}>
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Row justify="space-between" gutter={10}>
            <Col span={16}>
              <Form.Item label="Account" name="account" rules={[{ required: true }]}>
                <Select onChange={onAccountChange} virtual={false}>
                  {sort(adminUser?.accounts).map((account) => (
                    <Select.Option key={account._id}>{getName(account)}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Amount" name="amount" rules={[{ type: 'number', required: true }, natural]}>
                <InputNumber addonAfter={toCurrency(selectedAccount?.currency)} />
              </Form.Item>
            </Col>
          </Row>
          {ACCOUNT_REGIONS[selectedAccount?.type] && (
            <Form.Item label="Region" name="region" rules={[{ required: true }]}>
              <CountrySelect />
            </Form.Item>
          )}
          <Form.Item label="Transfer Address" name="transferAddress" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Comment" name="comment" style={{ marginBottom: 0 }}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item style={{ textAlign: 'right', marginBottom: 0 }} name="hiddenComment" valuePropName="checked">
            <Checkbox>Hidden</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateTransferRequestModal;
