export const ACCOUNT_CONFIGURATION = [
  {
    key: 'Skrill',
    name: 'Skrill',
    type: 'purse',
    groupId: null,
    currencies: ['USD', 'EUR'],
    vipAvailable: true,
    multipleScreenNames: false,
    crossRegional: false,
    transfer: {
      available: true,
      equalToScreenName: false,
      type: 'email',
      validate: 'email',
    },
  },
  {
    key: 'Neteller',
    name: 'Neteller',
    type: 'purse',
    groupId: null,
    currencies: ['USD', 'EUR'],
    vipAvailable: true,
    multipleScreenNames: false,
    crossRegional: false,
    transfer: {
      available: true,
      equalToScreenName: false,
      type: 'email',
      validate: 'email',
    },
  },
  {
    key: 'Luxon',
    name: 'Luxon',
    type: 'purse',
    groupId: null,
    currencies: ['USD', 'EUR'],
    vipAvailable: true,
    multipleScreenNames: false,
    crossRegional: true,
    transfer: {
      available: true,
      equalToScreenName: false,
      type: 'email',
      validate: 'email',
    },
  },
  {
    key: 'PokerStars',
    name: 'PokerStars',
    type: 'network',
    rooms: [
      {
        key: 'PS',
        name: 'PS',
        type: 'room',
        groupId: 'PokerStars',
        currencies: ['USD'],
        vipAvailable: false,
        multipleScreenNames: false,
        crossRegional: true,
        transfer: {
          available: true,
          equalToScreenName: true,
          type: 'text',
          validate: 'string',
        },
      },
    ],
  },
  {
    key: 'PokerStars(FR-ES-PT)',
    name: 'PokerStars(FR-ES-PT)',
    type: 'network',
    rooms: [
      {
        key: 'PSes',
        name: 'PSes',
        type: 'room',
        groupId: 'PokerStars(FR-ES-PT)',
        currencies: ['EUR'],
        vipAvailable: false,
        multipleScreenNames: false,
        crossRegional: true,
        transfer: {
          available: false,
          equalToScreenName: false,
          type: 'text',
          validate: 'string',
        },
      },
    ],
  },
  {
    key: 'Winamax.fr',
    name: 'Winamax.fr',
    type: 'network',
    rooms: [
      {
        key: 'WMX',
        name: 'WMX',
        type: 'room',
        groupId: 'Winamax.fr',
        currencies: ['EUR'],
        vipAvailable: false,
        multipleScreenNames: true,
        crossRegional: true,
        transfer: {
          available: false,
          equalToScreenName: false,
          type: 'text',
          validate: 'string',
        },
      },
    ],
  },
  {
    key: 'GGNetwork',
    name: 'GGNetwork',
    type: 'network',
    rooms: [
      {
        key: 'PokerOK',
        name: 'ПокерOK',
        type: 'room',
        groupId: 'GGNetwork',
        currencies: ['USD'],
        vipAvailable: false,
        multipleScreenNames: true,
        crossRegional: true,
        transfer: {
          available: true,
          equalToScreenName: false,
          type: 'email',
          validate: 'email',
        },
      },
      {
        key: 'GGPoker',
        name: 'GGPoker',
        type: 'room',
        groupId: 'GGNetwork',
        currencies: ['USD'],
        vipAvailable: false,
        multipleScreenNames: true,
        crossRegional: true,
        transfer: {
          available: true,
          equalToScreenName: false,
          type: 'email',
          validate: 'email',
        },
      },
      {
        key: 'BetKings',
        name: 'BetKings',
        type: 'room',
        groupId: 'GGNetwork',
        currencies: ['USD'],
        vipAvailable: false,
        multipleScreenNames: true,
        crossRegional: true,
        transfer: {
          available: false,
          equalToScreenName: false,
          type: 'text',
          validate: 'string',
        },
      },
      {
        key: 'OlyBet',
        name: 'OlyBet',
        type: 'room',
        groupId: 'GGNetwork',
        currencies: ['EUR'],
        vipAvailable: false,
        multipleScreenNames: true,
        crossRegional: true,
        transfer: {
          available: false,
          equalToScreenName: false,
          type: 'text',
          validate: 'string',
        },
      },
      {
        key: 'Natural8',
        name: 'Natural8',
        type: 'room',
        groupId: 'GGNetwork',
        currencies: ['USD'],
        vipAvailable: false,
        multipleScreenNames: true,
        crossRegional: true,
        transfer: {
          available: true,
          equalToScreenName: false,
          type: 'email',
          validate: 'email',
        },
      },
    ],
  },
  {
    key: '888Poker',
    name: '888Poker',
    type: 'network',
    rooms: [
      {
        key: '888',
        name: '888',
        type: 'room',
        groupId: '888Poker',
        currencies: ['USD'],
        vipAvailable: false,
        multipleScreenNames: true,
        crossRegional: true,
        transfer: {
          available: true,
          equalToScreenName: true,
          type: 'text',
          validate: 'string',
        },
      },
    ],
  },
  {
    key: '888Poker(ES-PT)',
    name: '888Poker(ES-PT)',
    type: 'network',
    rooms: [
      {
        key: '888.ES',
        name: '888.ES',
        type: 'room',
        groupId: '888Poker(ES-PT)',
        currencies: ['EUR'],
        vipAvailable: false,
        multipleScreenNames: false,
        crossRegional: true,
        transfer: {
          available: false,
          equalToScreenName: false,
          type: 'text',
          validate: 'string',
        },
      },
    ],
  },
  {
    key: 'PartyPoker',
    name: 'PartyPoker',
    type: 'network',
    rooms: [
      {
        key: 'PartyPoker',
        name: 'PartyPoker',
        type: 'room',
        groupId: 'PartyPoker',
        currencies: ['USD'],
        multipleScreenNames: false,
        crossRegional: true,
        transfer: {
          available: false,
          equalToScreenName: false,
          type: 'text',
          validate: 'string',
        },
      },
      {
        key: 'Optibet',
        name: 'Optibet',
        type: 'room',
        groupId: 'PartyPoker',
        currencies: ['USD', 'EUR'],
        multipleScreenNames: false,
        crossRegional: true,
        transfer: {
          available: false,
          equalToScreenName: false,
          type: 'text',
          validate: 'string',
        },
      },
    ],
  },
  {
    key: 'WPN',
    name: 'WPN',
    type: 'network',
    rooms: [
      {
        key: 'PokerKing',
        name: 'PokerKing',
        type: 'room',
        groupId: 'WPN',
        currencies: ['USD'],
        multipleScreenNames: true,
        crossRegional: true,
        transfer: {
          available: true,
          equalToScreenName: true,
          type: 'text',
          validate: 'string',
        },
      },
      {
        key: 'ACR',
        name: 'ACR',
        type: 'room',
        groupId: 'WPN',
        currencies: ['USD'],
        multipleScreenNames: true,
        crossRegional: true,
        transfer: {
          available: true,
          equalToScreenName: true,
          type: 'text',
          validate: 'string',
        },
      },
      {
        key: 'Ya Poker',
        name: 'Ya Poker',
        type: 'room',
        groupId: 'WPN',
        currencies: ['USD'],
        multipleScreenNames: true,
        crossRegional: true,
        transfer: {
          available: true,
          equalToScreenName: true,
          type: 'text',
          validate: 'string',
        },
      },
      {
        key: 'Black Chip Poker',
        name: 'Black Chip Poker',
        type: 'room',
        groupId: 'WPN',
        currencies: ['USD'],
        multipleScreenNames: true,
        crossRegional: true,
        transfer: {
          available: false,
          equalToScreenName: false,
          type: 'text',
          validate: 'string',
        },
      },
    ],
  },
  {
    key: 'iPoker',
    name: 'iPoker',
    type: 'network',
    rooms: [
      {
        key: 'Redstar',
        name: 'Redstar',
        type: 'room',
        groupId: 'iPoker',
        currencies: ['USD', 'EUR'],
        multipleScreenNames: true,
        crossRegional: true,
        transfer: {
          available: true,
          equalToScreenName: false,
          type: 'text',
          validate: 'string',
        },
      },
      {
        key: 'Titan',
        name: 'Titan',
        type: 'room',
        groupId: 'iPoker',
        currencies: ['USD', 'EUR'],
        multipleScreenNames: true,
        crossRegional: true,
        transfer: {
          available: false,
          equalToScreenName: false,
          type: 'text',
          validate: 'string',
        },
      },
      {
        key: 'Betsafe',
        name: 'Betsafe',
        type: 'room',
        groupId: 'iPoker',
        currencies: ['USD', 'EUR'],
        multipleScreenNames: true,
        crossRegional: true,
        transfer: {
          available: false,
          equalToScreenName: false,
          type: 'text',
          validate: 'string',
        },
      },
      {
        key: 'BetFair',
        name: 'BetFair',
        type: 'room',
        groupId: 'iPoker',
        currencies: ['USD', 'EUR'],
        multipleScreenNames: true,
        crossRegional: true,
        transfer: {
          available: false,
          equalToScreenName: false,
          type: 'text',
          validate: 'string',
        },
      },
      {
        key: 'ChampionPoker',
        name: 'ChampionPoker',
        type: 'room',
        groupId: 'iPoker',
        currencies: ['EUR'],
        multipleScreenNames: true,
        crossRegional: true,
        transfer: {
          available: false,
          equalToScreenName: false,
          type: 'text',
          validate: 'string',
        },
      },
    ],
  },
  {
    key: 'Chico',
    name: 'Chico',
    type: 'network',
    rooms: [
      {
        key: 'TigerGaming',
        name: 'TigerGaming',
        type: 'room',
        groupId: 'Chico',
        currencies: ['USD'],
        multipleScreenNames: false,
        crossRegional: true,
        transfer: {
          available: false,
          equalToScreenName: false,
          type: 'text',
          validate: 'string',
        },
      },
      {
        key: 'BetOnline',
        name: 'BetOnline',
        type: 'room',
        groupId: 'Chico',
        currencies: ['USD'],
        multipleScreenNames: false,
        crossRegional: true,
        transfer: {
          available: false,
          equalToScreenName: false,
          type: 'text',
          validate: 'string',
        },
      },
      {
        key: 'SportsBetting',
        name: 'SportsBetting',
        type: 'room',
        groupId: 'Chico',
        currencies: ['USD'],
        multipleScreenNames: false,
        crossRegional: true,
        transfer: {
          available: false,
          equalToScreenName: false,
          type: 'text',
          validate: 'string',
        },
      },
    ],
  },
  {
    key: 'CoinPoker',
    name: 'CoinPoker',
    type: 'network',
    rooms: [
      {
        key: 'CoinPoker',
        name: 'CoinPoker',
        type: 'room',
        groupId: 'CoinPoker',
        currencies: ['USD'],
        multipleScreenNames: false,
        crossRegional: true,
        transfer: {
          available: true,
          equalToScreenName: true,
          type: 'text',
          validate: 'string',
        },
      },
    ],
  },
  {
    key: '4Poker',
    name: '4Poker',
    type: 'network',
    rooms: [
      {
        key: '4Poker',
        name: '4Poker',
        type: 'room',
        groupId: '4Poker',
        currencies: ['USD'],
        multipleScreenNames: false,
        crossRegional: true,
        transfer: {
          available: false,
          equalToScreenName: false,
          type: 'text',
          validate: 'string',
        },
      },
    ],
  },
  {
    key: 'WPT Global',
    name: 'WPT Global',
    type: 'network',
    rooms: [
      {
        key: 'WPT Global',
        name: 'WPT Global',
        type: 'room',
        groupId: 'WPT Global',
        currencies: ['USD'],
        multipleScreenNames: false,
        crossRegional: true,
        transfer: {
          available: false,
          equalToScreenName: false,
          type: 'text',
          validate: 'string',
        },
      },
    ],
  },
];

export const INITIAL_STATE = {
  key: '',
  name: '',
  type: '',
  groupId: '',
  currencies: [],
  multipleScreenNames: false,
  crossRegional: false,
  transfer: {
    available: false,
    equalToScreenName: false,
    type: 'text',
  },
};

export const INITIAL_VALUES = {
  active: true,
  ableToTransfer: false,
};

export const UNIQUE_VALIDATOR_MESSAGE = 'Account already exists';
