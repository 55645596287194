import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';
import { ACCOUNT_REGIONS } from 'config/accounts';
import { useToggle } from 'hooks/useToggle';
import { getMyAccounts, selectList } from 'store/accounts';
import AccountRegions from 'components/Accounts/AccountRegions';

const AccountRegionsModal = () => {
  const dispatch = useDispatch();
  const { list: accounts } = useSelector(selectList);
  const { active, activate, deactivate } = useToggle();
  const { t } = useTranslation();

  const fetchAccounts = useCallback(() => dispatch(getMyAccounts()), [dispatch]);

  const actionRequired = useMemo(
    () => accounts.filter((acc) => ACCOUNT_REGIONS[acc.type] && (!acc.region || !acc.status)).length > 0,
    [accounts],
  );

  useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts]);

  useEffect(() => {
    if (actionRequired) {
      activate();
    }
  }, [actionRequired, activate]);

  return (
    <Modal
      open={active}
      title={t('accounts.empty_transfer_config_notification')}
      closable={!actionRequired}
      onCancel={deactivate}
      footer={
        <Button type="primary" disabled={actionRequired} onClick={deactivate}>
          {t('save')}
        </Button>
      }
    >
      <AccountRegions onUpdate={fetchAccounts} />
    </Modal>
  );
};

export default AccountRegionsModal;
