import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Col, Descriptions, Row, Space, Typography } from 'antd';
import { getDegree } from 'utils/users';
import { selectRates } from 'store/rates';
import { List as AccountList } from '../Accounts/List';

const { Text } = Typography;

export const Confirmation = ({ form, accounts }) => {
  const { latest } = useSelector(selectRates);

  const startBalance = useMemo(() => {
    const eur = accounts.filter((a) => a.currency === 'EUR').reduce((acc, a) => acc + a.balance, 0);
    const eurToUsd = eur * latest?.ratio;
    const usd = accounts.filter((a) => a.currency === 'USD').reduce((acc, a) => acc + a.balance, 0);
    const total = eurToUsd + usd;

    return {
      eur,
      eurToUsd,
      usd,
      total,
    };
  }, [accounts]);

  const user = form.getFieldsValue(true);

  return (
    <>
      <Space style={{ marginBottom: 24 }}>
        <Avatar src={user.avatar || '//www.gravatar.com/avatar/4f64c9f81bb0d4ee969aaf7b4a5a6f40?s=200&r=pg&d=mm'} />
        <Text strong>{user.username}</Text>
      </Space>
      <Row gutter={10} style={{ marginBottom: 24 }}>
        <Col span={12}>
          <Descriptions column={1} bordered>
            <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
            <Descriptions.Item label="Full Name">
              {user.name} {user.discordId && `(${user.discordId})`}
            </Descriptions.Item>
            <Descriptions.Item label="Country">{user.country}</Descriptions.Item>
            <Descriptions.Item label="Course">{user.scheduleCourse}</Descriptions.Item>
            <Descriptions.Item label="Degree">{getDegree(user.scheduleCourse)}</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={12}>
          <Descriptions contentStyle={{ textAlign: 'center' }} bordered column={1}>
            <Descriptions.Item label="TOTAL USD">{startBalance.usd?.toFixed(2)}$</Descriptions.Item>
            <Descriptions.Item label="TOTAL EUR">
              {startBalance.eur?.toFixed(2)}€ ({startBalance.eurToUsd?.toFixed(2)}$)
            </Descriptions.Item>
            <Descriptions.Item label="START BALANCE">{startBalance.total?.toFixed(2)}$</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      {accounts.length > 0 ? <AccountList list={accounts} interactive={false} /> : <Text>No accounts</Text>}
    </>
  );
};
