import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Col, Row } from 'antd';
import { getMyAccounts } from 'store/accounts';
import { getPreferences } from 'store/preferences';
import AccountRegions from 'components/Accounts/AccountRegions';
import MyRooms from './fragments/MyRooms';

const Settings = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMyAccounts());
    dispatch(getPreferences());
  }, [dispatch]);

  return (
    <Row gutter={10}>
      <MyRooms />
      <Col span={16}>
        <Card title="Account regions">
          <AccountRegions />
        </Card>
      </Col>
    </Row>
  );
};

export default Settings;
