import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Table } from 'antd';
import { useTableParams } from 'hooks/useTableParams';
import { useArchiveColumns } from 'hooks/playing-myself/useArchiveColumns';
import useLoading from 'hooks/useLoading';
import { rowClassName } from 'utils/playing-myself';
import { getMyArchiveRequests, selectArchive } from 'store/playing-myself';
import ModalController from 'components/Modal/ModalController';
import CreateReportModal from 'components/PlayingMyself/CreateReportModal/CreateReportModal';
import { ID as CREATE_REPORT_MODAL_ID } from 'components/PlayingMyself/CreateReportModal/config';
import { ReportCell } from './ReportCell';

const reportsFilter = [
  { value: null, label: 'All' },
  { value: 'expired', label: 'Expired' },
  { value: 'waiting', label: 'Waiting' },
];

export const ArchivedRequestsTable = () => {
  const dispatch = useDispatch();
  const archive = useSelector(selectArchive);
  const { t } = useTranslation();
  const { params, handleTableChange, addFilter } = useTableParams();
  const { loading, execute } = useLoading();

  const fetchRequests = useCallback(
    () => execute(() => dispatch(getMyArchiveRequests(params))),
    [dispatch, params, execute],
  );

  const columns = useArchiveColumns(
    [
      {
        title: (
          <Select
            placeholder={t('playingmyself.table.report')}
            options={reportsFilter}
            onChange={addFilter('expired')}
          />
        ),
        dataIndex: 'reported',
        key: 'reported',
        align: 'center',
        render: (_, record) => <ReportCell record={record} afterReport={fetchRequests} />,
      },
    ],
    ['type', 'tournamentCourse', 'scheduleCourse', 'user', 'createdAt'],
  );

  useEffect(() => {
    fetchRequests();
  }, [fetchRequests]);

  return (
    <>
      <Table
        loading={loading}
        onChange={handleTableChange}
        pagination={{
          total: archive.totalDocs,
          current: archive.page,
          pageSize: archive.limit,
          style: { marginBottom: 0 },
        }}
        columns={columns}
        rowKey="_id"
        dataSource={archive.docs}
        rowClassName={rowClassName}
        scroll={{ x: 'auto' }}
      />
      <ModalController id={CREATE_REPORT_MODAL_ID}>
        <CreateReportModal />
      </ModalController>
    </>
  );
};
