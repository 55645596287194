import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Col, Row } from 'antd';
import { getMyActivePackage } from 'store/packages';
import { getTotals } from 'store/statistics';
import AccountsTable from './fragments/AccountsTable/AccountsTable';
import Statistics from './fragments/Statistics/Statistics';
import { PackageInfo } from './fragments/PackageInfo/PackageInfo';
import { SplitModal } from 'pages/Dashboard/Index/fragments/SplitModal/SplitModal';
import { ClosedTable } from "./fragments/SplitTable/ClosedTable";

const Index = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMyActivePackage());
    dispatch(getTotals());
  }, [dispatch]);

  return (
    <>
      <Row justify="space-between" gutter={[10, 24]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={7}>
          <Card>
            <AccountsTable />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={9}>
          <Card>
            <Statistics />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
          <Card actions={[<SplitModal key="split" />]}>
            <PackageInfo />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card>
            <ClosedTable />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Index;
