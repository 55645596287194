import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import { selectList, updateRegion } from 'store/accounts';
import { toCurrency } from 'utils/format';
import CountrySelect from 'components/CountrySelect';
import AccountStatusSelect from 'components/Accounts/AccountStatusSelect';

const CONFIGURABLE_ACCOUNTS = ['Skrill', 'Neteller'];

const AccountRegions = ({ onUpdate }) => {
  const dispatch = useDispatch();
  const { list: accounts, loading } = useSelector(selectList);

  const dataSource = accounts.filter((account) => CONFIGURABLE_ACCOUNTS.includes(account.type));

  const updateConfig = useCallback(
    async (id, values) => {
      await dispatch(updateRegion(id, values));
      onUpdate?.();
    },
    [dispatch, onUpdate],
  );

  const columns = useMemo(
    () => [
      {
        title: 'Account',
        dataIndex: 'type',
        key: 'type',
        render: (type, account) => `${type} ${toCurrency(account.currency)}`,
      },
      {
        title: 'Region',
        dataIndex: 'region',
        key: 'region',
        render: (region, config) => (
          <CountrySelect defaultValue={region} onChange={(val) => updateConfig(config._id, { region: val })} />
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status, config) => (
          <AccountStatusSelect
            type={config.type}
            defaultValue={status}
            onChange={(val) => updateConfig(config._id, { status: val })}
          />
        ),
      },
    ],
    [updateConfig],
  );

  return (
    dataSource.length > 0 && (
      <Table loading={loading} rowKey="_id" columns={columns} dataSource={dataSource} pagination={false} />
    )
  );
};

export default AccountRegions;
